<template>
  <div>
    <EditDialog width="80%" :isReturn="true" @closeFn="$emit('offsetCancel')" :isShow.sync="isShow">
      <div slot="title" class="title">冲红单据详情</div>
      <div slot="content" class="content" v-loading="loading">
        <!-- 申请单 begin -->
        <div>
          <el-form ref="form" :model="form">
            <el-descriptions border class="margin-top w_100" :column="7" direction="vertical">
              <el-descriptions-item
                labelClassName="labelClassName"
                label="单据编号"
                v-if="offsetId"
              >
                <el-form-item label-width="0px" prop="expenseNumber" style="width: 100%">
                  {{ form.expenseNumber }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" label="申请人">
                <el-form-item label-width="0px" prop="createName" style="width: 100%">
                  {{ form.createName }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label"> 申请日期 </template>
                <el-form-item label-width="0px" prop="taskDate" style="width: 100%">
                  {{ form.taskDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item labelClassName="labelClassName" :span="1">
                <template slot="label">
                  <span style="color: red">*</span>
                  开票日期
                </template>
                <el-form-item ref="invoiceDate" prop="invoiceDate" style="width: 100%">
                  {{ form.invoiceDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" :span="1">
                <template slot="label">
                  <span style="color: red">*</span>
                  销售方
                </template>
                <el-form-item ref="companyType" prop="companyType" style="width: 100%">
                  {{ form.companyType | dict(dictData.companyType) }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" :span="offsetId ? 1 : 2">
                <template slot="label">
                  <span style="color: red">*</span>
                  购买方
                </template>
                <el-form-item
                  ref="customersUnit"
                  label-width="0px"
                  prop="customersUnit"
                  style="width: 100%"
                >
                  {{ form.customersUnit }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label">
                  <span style="color: red">*</span>
                  纳税人识别号
                </template>
                <el-form-item ref="taxpayerNumber" prop="taxpayerNumber" style="width: 100%">
                  {{ form.taxpayerNumber }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item labelClassName="labelClassName" :span="4">
                <template slot="label">
                  <span style="color: red">*</span>
                  项目名称
                </template>
                <el-form-item ref="projectName" prop="projectName">
                  {{ form.projectName }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label"> 项目分管高管 </template>
                <el-form-item ref="fzManagerName" prop="fzManagerName">
                  {{ form.fzManagerName }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label"> 项目所属部门 </template>
                <el-form-item
                  ref="deptClassifyName"
                  label-width="0px"
                  prop="deptId"
                  style="width: 100%"
                >
                  {{ form.deptClassifyName }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label"> 项目来源 </template>
                <el-form-item ref="projectSource" label-width="0px" prop="projectSource">
                  {{ form.projectSource | dict(dictData.projectSource) }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item
                labelClassName="labelClassName"
                :span="5"
                style="padding-top: 2px"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  合同名称
                </template>

                <el-form-item ref="agreementName" prop="agreementName">
                  {{ form.agreementName }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label"> 合同编号 </template>
                <el-form-item ref="agreementNum" label-width="0px" prop="agreementNum">
                  {{ form.agreementNum }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label"> 合同总金额 （元） </template>
                <el-form-item ref="actualContractAmount" prop="actualContractAmount">
                  {{ form.actualContractAmount | applyAmount }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label"> <span>发票种类</span></template>
                <el-form-item ref="invoiceLines" prop="invoiceLines"
                  >{{ form.invoiceLines | dict(dictData.invoiceLines) }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" v-if="form.status == 100">
                <template slot="label">
                  <span>发票状态</span>
                </template>
                <el-form-item ref="offsetStatus" prop="offsetStatus">
                  <span>{{ form.offsetStatus | dict(dictData.offsetStatus) }}</span>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label">
                  <span>冲红单据编号</span>
                </template>
                <el-form-item ref="" prop="">
                  <span v-if="form.invoiceOffse">{{ form.invoiceOffse.expenseNumber }}</span>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item labelClassName="labelClassName" :span="1">
                <template slot="label">
                  <span style="color: red">*</span>
                  开具发票类型
                </template>
                <el-form-item label-width="0px" prop="invoiceType" ref="invoiceType">
                  {{ form.invoiceType | dict(dictData.invoiceType) }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" :span="1">
                <template slot="label">
                  <span style="color: red">*</span>
                  增值税类型
                </template>
                <el-form-item label-width="0px" prop="vatType" ref="vatType">
                  {{ form.vatType | transitionType(zzsList) }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="labelClassName"
                :span="form.status == 100 ? 1 : 2"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  开票内容
                </template>
                <el-form-item label-width="0px" prop="vatData" ref="vatData">
                  {{ form.vatData | transitionData(dictData.VatDataAll) }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName">
                <template slot="label">
                  <span style="color: red">*</span>
                  增值税率（%）
                </template>
                <el-form-item label-width="0px" prop="vatRate" ref="vatRate">
                  {{ form.vatRate }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" :span="5">
                <template slot="label">
                  <span style="color: red">*</span>
                  地址
                </template>
                <el-form-item prop="address" ref="address"> {{ form.address }} </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" :span="2">
                <template slot="label">
                  <span style="color: red">*</span>
                  电话
                </template>
                <el-form-item prop="phone" ref="phone"> {{ form.phone }} </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" :span="5">
                <template slot="label">
                  <span style="color: red">*</span>
                  开户行
                </template>
                <el-form-item prop="openingBank" ref="openingBank">
                  {{ form.openingBank }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item labelClassName="labelClassName" :span="2">
                <template slot="label">
                  <span style="color: red">*</span>
                  账号
                </template>
                <el-form-item prop="account" ref="account"> {{ form.account }} </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item labelClassName="labelClassName" :span="7">
                <template slot="label">
                  <span style="color: red">*</span>
                  发票备注
                </template>
                <el-form-item ref="invoiceRemark" prop="invoiceRemark" style="width: 100%">
                  {{ form.invoiceRemark }}
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>

          <el-descriptions v-if="form.status == 100" :column="2" border direction="vertical">
            <el-descriptions-item label="发票编号">{{ form.invoicingNum }}</el-descriptions-item>
            <el-descriptions-item label="财务开票备注">{{ form.remark }}</el-descriptions-item>
            <el-descriptions-item label="附件">
              <span
                v-for="(item, index) in form.affirmCostFileList"
                :key="index"
                class="invoice-file-text"
              >
                <a
                  class="fj-text"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  :href="`${filepath}${item.filePath}`"
                >
                  {{ item.fileName }}
                </a>
              </span>
            </el-descriptions-item>
          </el-descriptions>

          <div class="ticket_box">
            <div class="ticket_div">
              <p>
                本项目开票共
                <span>{{ form.periods }}</span>
                期， 本次申请开具第
                {{ form.currentPeriods }}
                次
              </p>
            </div>
            <el-table ref="multipleTable" border :data="form.invoiceApplicationDetailList">
              <el-table-column fixed="left" label="序号" type="index" align="center" width="80">
              </el-table-column>
              <el-table-column
                prop="stageName"
                fixed="left"
                label="阶段名称"
                min-width="200"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.stageName }}
                </template>
              </el-table-column>
              <el-table-column prop="paymentAmount" label="本期金额" min-width="110" align="center">
                <template slot-scope="scope">
                  {{ scope.row.paymentAmount | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column
                prop="invoiceAmount"
                :show-overflow-tooltip="false"
                label="金额"
                min-width="110"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.invoiceAmount | applyAmount }}
                </template>
              </el-table-column>
              <!-- 流程结束才能看到 -->
              <template v-if="form.status == 100">
                <el-table-column prop="vatType" label="增值税类型" min-width="320" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.vatType | transitionType(zzsList) }}
                  </template>
                </el-table-column>
                <el-table-column prop="VatData" label="开票内容" min-width="320" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.vatData | transitionData(dictData.VatDataAll) }}
                  </template>
                </el-table-column>
                <el-table-column prop="vatRate" label="增值税税率" min-width="110" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.vatRate"> {{ scope.row.vatRate }} %</span>
                  </template>
                </el-table-column>
                <el-table-column prop="vatFee" label="增值税" min-width="110" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.vatFee | applyAmount }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="additionalTaxFee"
                  label="附加税"
                  min-width="110"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.additionalTaxFee | applyAmount }}
                  </template>
                </el-table-column>
                <el-table-column prop="stampTaxFee" label="印花税" min-width="110" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.stampTaxFee | applyAmount }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="taxesFee"
                  fixed="right"
                  min-width="110"
                  label="总税费"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.taxesFee | applyAmount }}
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <el-descriptions title="" :column="2" border>
              <el-descriptions-item label="合计" labelStyle="width:10%">
                {{ amount | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item label="金额大写" labelStyle="width:10%">
                {{ amount | numberParseChina }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <!-- 意见区 -->
        <OpinionArea
          ref="OpinionArea"
          :procInstId="form.processInstId"
          :instInvolved="instInvolved"
          :title="'发票申请进度'"
        ></OpinionArea>
      </div>
      <div slot="footer" class="footer">
        <el-button type="info" plain @click="$emit('offsetCancel')">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'OffsetDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    offsetId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false, // 表格 加载
      dictData: {
        invoiceType: [],
        VatDataAll: [],
        projectSource: [],
        companyType: [],
        invoiceLines: [],
        offsetStatus: [],
      },
      zzsList: [], //增值税类型
      form: {},
      instInvolved: {}, //当前进度处理人数组
      //文件上传
      filepath: process.env.VUE_APP_FILEPATH,
    }
  },
  computed: {
    amount() {
      let num = 0
      if (this.form.invoiceApplicationDetailList) {
        this.form.invoiceApplicationDetailList.forEach(v => {
          if (v.invoiceAmount) {
            num += Number(String(v.invoiceAmount).replace(/,/g, ''))
          }
        })
      }
      return num
    },
  },
  created() {
    this.getDict()
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (val) {
        if (val && this.offsetId) {
          this.getData()
        }
      },
    },
  },
  methods: {
    getData() {
      this.loading = true
      this.$api.invoice
        .selectDetailById(this.offsetId)
        .then(res => {
          this.form = res.data
          this.instInvolved = {
            instInvolvedUserName: res.data.instInvolvedUserName
              ? res.data.instInvolvedUserName
              : [],
            status: res.data.status,
          }
          if (this.form.vatType) {
            this.$api.vat
              .getVatDataList({ pageNow: 1, pageSize: 999 })
              .then(res => {
                if (res.data?.records) {
                  this.dictData.VatDataAll = res.data.records
                }
              })
              .catch(err => {
                console.log(err)
              })
          }

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getDict() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE_FULL', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('OFFSET_STATUS', true)
        .then(res => {
          this.dictData.offsetStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('INVOICE_LINES', true)
        .then(res => {
          this.dictData.invoiceLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('INVOICE_TYPE', true)
        .then(res => {
          this.dictData.invoiceType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE_COLLECT', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
}
</script>

<style scoped lang="scss">
.ticket_box {
  margin-top: 16px;

  .ticket_div {
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;

    span {
      display: inline-block;
      margin: 0 4px;
      text-decoration: underline;
    }

    .section {
      width: 60px;
      text-align: center;
    }

    .lineBtn {
      float: right;
      margin-top: 10px;
    }
    .jilvBtn {
      float: right;
      margin-left: 8px;
      margin-top: 10px;
    }
  }
}

.content {
  height: calc(90vh - 164px);
  overflow: auto;
}
.w_100 {
  width: 100%;
}
/deep/.labelClassName {
  width: 14.2%;
}
</style>
